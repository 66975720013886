<template>
    <div class=" w-100 flex-1" ref="monacoEditorContainer"></div>
</template>
<script setup>
    import {ref,onMounted,computed, onUpdated,onUnmounted} from 'vue';
    import getMonaco from 'o365.vue.modules.Monaco.ts';
    const monacoEditorContainer = ref(null);
    
    const props = defineProps({
        primKey: {
            required: false
        },
        readonly: {
            required: false,
            type:Boolean,
            default:false
        },
        modelValue: {
            required: false,
            type: String
        },
        fileName: {
            required: false,
            type: String
        },
        lang: {
            required: false,
            type: String
        },
        dataObject:{
            required:false
        },
        keepModelsAfterDispose:{
            type:Boolean,
            default:false
        },
        formatJSON:{
            type:Boolean,
            default:false
        },
        focusOnOpen:{
            type:Boolean,
            default:false
        }
    });


    const emit = defineEmits(['update:modelValue']);
    
    let control = null;
    let initiated = false;


    onUpdated(()=>{
        initMonaco();
    })

    onMounted(()=>{
        initMonaco();
    })

    onUnmounted(()=>{
        console.log(props)
        initiated = false;
       // if(control && control.dispose) control.dispose();
    })

    const initMonaco = function(){

        control = getMonaco({
            element:monacoEditorContainer.value,
            key:props.primKey,
            fileName:props.fileName,
            lang:props.lang,
            modelValue:props.formatJSON && props.lang == 'json'?formatJSON(props.modelValue):props.modelValue,
            dataObject:props.dataObject,
            readonly:props.readonly,
            keepModelsAfterDispose:props.keepModelsAfterDispose,
        })



        if(document.documentElement.getAttribute("data-bs-theme") == "dark"){
            control.editor._themeService.setTheme("vs-dark");
        }
        
        if(!initiated){
            initiated = true;
            control.editor.onDidChangeModelContent(() => {

                emit('update:modelValue', control.editor.getModel().getValue());
            });
            control.editor?.focus()
        }
   
        
    }
    const getControl = ()=>{
        return control;
    }

    function formatJSON(pValue){
       
        try{
             let vValue = JSON.parse(pValue);
            return JSON.stringify(vValue,null,4)
        }catch{
            return JSON.stringify(pValue,null,4);
        }
    }

    defineExpose({ getControl });
   
</script>